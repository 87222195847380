import React from 'react'
import css from './style.module.scss'
import { InputText, Button, Radio, Select, Phone, Checkbox } from 'components/ui'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { userFieldNames as fieldNames } from 'components/user/constants'
import { Container, Col, Row } from 'styled-bootstrap-grid'

UserSignUp.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onClickLogIn: PropTypes.func.isRequired
}

export default function UserSignUp({
  handleChange,
  handleSubmit,
  setFieldValue,
  values,
  onClickLogIn,
  errors,
  touched,
  cities,
}) {
  return (
    <div className={cn(css.signUp, 'form-layout')}>
      <div className={css.important}>
        <p className={css.importantText}>
          Если вы уже зарегистрированы на <a href='https://shanatova.ru/' target='_blank' rel='noopener noreferrer'>shanatova.ru</a>, то регистрация не требуется. Войдите в свою учетную запись по зарегистрированному ранее номеру телефона и паролю.
        </p>
        <Container>
          <Row>
            <Col col='12' lg='6'>
              <span
                className="label-2 button-blue"
                onClick={onClickLogIn}
                role='button'
                tabIndex='0'
                onKeyDown={() => {}}
              >
                Войти
              </span>
            </Col>
          </Row>
        </Container>
      </div>
      <form onSubmit={handleSubmit}>
        <Container>
          <Row>
            <Col col='12' lg='6'>
              <div className='form-field'>
                <InputText
                  placeholder="Фамилия"
                  name={fieldNames.LAST_NAME}
                  onChange={handleChange}
                  value={values[fieldNames.LAST_NAME]}
                  error={touched[fieldNames.LAST_NAME] && errors[fieldNames.LAST_NAME]}
                />
              </div>
            </Col>
            <Col col='12' lg='6'>
              <div className='form-field'>
                <InputText
                  placeholder="Имя"
                  name={fieldNames.FIRST_NAME}
                  value={values[fieldNames.FIRST_NAME]}
                  onChange={handleChange}
                  error={touched[fieldNames.FIRST_NAME] && errors[fieldNames.FIRST_NAME]}
                />
              </div>
            </Col>
            <Col col='12' lg='6'>
              <div className='form-field'>
                <InputText
                  placeholder="Отчество (необязательно)"
                  name={fieldNames.PATRONYMIC}
                  value={values[fieldNames.PATRONYMIC]}
                  onChange={handleChange}
                  error={touched[fieldNames.PATRONYMIC] && errors[fieldNames.PATRONYMIC]}
                />
              </div>
            </Col>
            <Col col='12' lg='6'>
              <div className='form-field'>
                <InputText
                  placeholder="Дата рождения"
                  name={fieldNames.BIRTH_DAY}
                  value={values[fieldNames.BIRTH_DAY]}
                  onChange={handleChange}
                  type="date"
                  error={touched[fieldNames.BIRTH_DAY] && errors[fieldNames.BIRTH_DAY]}
                />
              </div>
            </Col>
            <Col col='12' lg='6'>
              <div className='form-field'>
                <Phone
                  placeholder="Номер телефона"
                  name={fieldNames.PHONE}
                  setFieldValue={setFieldValue}
                  error={touched[fieldNames.PHONE] && errors[fieldNames.PHONE]}
                  country={'ru'}
                />
              </div>
            </Col>
            <Col col='12' lg='6'>
              <div className='form-field'>
                <InputText
                  placeholder="E-mail"
                  name={fieldNames.EMAIL}
                  value={values[fieldNames.EMAIL]}
                  onChange={handleChange}
                  error={touched[fieldNames.EMAIL] && errors[fieldNames.EMAIL]}
                />
              </div>
            </Col>
            <Col col='12' lg='6'>
              <div className='form-field'>  
                <Select
                  label="Город (необязательно)"
                  placeholder="Выберите город"
                  options={cities}
                  onChange={option => setFieldValue(fieldNames.CITY, {
                    id: option.value,
                    name: option.label,
                  })}
                  error={touched[fieldNames.CITY] && errors[fieldNames.CITY]}
                />
              </div>
            </Col>
            <Col col='12' lg='6'>
              <div className='form-field'>
                <InputText
                  placeholder="Адрес (необязательно)"
                  name={fieldNames.ADDRESS}
                  value={values[fieldNames.ADDRESS]}
                  onChange={handleChange}
                  error={touched[fieldNames.ADDRESS] && errors[fieldNames.ADDRESS]}
                />
              </div>
            </Col>
            <Col col='12' lg='6'>
              <div className='form-field'>
                <p className={cn(css.placeholder, 'label-2 dark-grey')}>Пол</p>

                <React.Fragment>
                  <div className={css.radioButtons}>
                    <div className={css.radioButton}>
                      <Radio
                        id="sign_up_male_gender"
                        value="1"
                        text="Мужской"
                        name={fieldNames.GENDER}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={css.radioButton}>
                      <Radio
                        id="sign_up_female_gender"
                        value="2"
                        onChange={handleChange}
                        name={fieldNames.GENDER}
                        text="Женский"
                      />
                    </div>
                  </div>

                  {
                    touched[fieldNames.GENDER] && errors[fieldNames.GENDER] &&
                      <p className='validation__error caption red'>{errors[fieldNames.GENDER]}</p>
                  }
                </React.Fragment>
              </div>
            </Col>
            <Col col='12'>
              <div className='form-field'>
                <Checkbox
                  id="agree"
                  name={fieldNames.AGREE}
                  value={values[fieldNames.AGREE]}
                  defaultChecked={values[fieldNames.AGREE]}
                  onChange={handleChange}
                  text="Нажимая кнопку “Продолжить”, я даю согласие на<br /><a href='/privacy-policy/' className='primary-color' target='_blank'>обработку персональных данных</a>&nbsp;и принимаю <a href='/terms-of-service/' className='primary-color' target='_blank'>пользовательское соглашение</a>"
                />
                {
                  touched[fieldNames.AGREE] && errors[fieldNames.AGREE] &&
                    <p className='validation__error caption red'>{errors[fieldNames.AGREE]}</p>
                }
              </div>
            </Col>
            <Col col='12'>
              <div className="form-button">
                <Row alignItems='center'>
                  <Col col='12' lg='6'>
                    <Button blue text="Продолжить" type="submit" />
                  </Col>
                  <Col col='12' lg='6'>
                    <p className='caption' style={{ padding: '10px 0' }}>
                      Заявки передаются местной еврейской общине вашего города. Осуществление заказа не гарантируется.
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  )
}
