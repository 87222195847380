export const actionTypes = {
  OPEN_POPUP: 'OPEN_POPUP',
  CLOSE_POPUP: 'CLOSE_POPUP'
}

export const popupTypes = {
  POPUP_LAYOUT: 'POPUP_LAYOUT',
  POPUP_ALERT: 'POPUP_ALERT'
}

export function openPopup(popupType, popupProps) {
  return {
    type: actionTypes.OPEN_POPUP,
    popupType,
    popupProps
  }
}

export function closePopup() {
  return {
    type: actionTypes.CLOSE_POPUP
  }
}
