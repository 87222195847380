import React, { useRef } from 'react'
import css from './style.module.scss'
import cn from 'classnames'
import { IconCheck } from 'components/icons'
import { useDropdown } from 'utils'
import { Link } from "gatsby"
import { useDispatch } from 'react-redux'
import { logout } from 'components/user/actions'

export default function () {
  const dispatch = useDispatch()

  const dropRef = useRef()
  const [isOpen, toggleIsOpen] = useDropdown({
    dropRef: dropRef
  })

  function onClickLogoutHandler() {
    dispatch(logout())
  }

  return (
    <div className={cn(css.userDropdown, 'desktop')}>

      <div
        className={css.dropdownButton}
        onClick={toggleIsOpen}
        role='button'
        tabIndex='0'
        onKeyDown={() => {}}
      >
        <p className="label-2 primary-color">Личный кабинет</p>
        <div className={css.dropdownArrow}>
          <IconCheck />
        </div>
      </div>

      <div className={css.dropdownMenu} ref={dropRef} hidden={!isOpen}>
        <ul>
          <li>
            <Link to="/account/" className="label-2 primary-color">
              Личные данные
            </Link>
          </li>
          <li>
            <Link to="/account/settings/" className="label-2 primary-color">
              Настройки аккаунта
            </Link>
          </li>
          <li>
            <span
              onClick={onClickLogoutHandler}
              className="label-2 pointer primary-color"
              role='button'
              tabIndex='0'
              onKeyDown={() => {}}
            >
              Выйти
            </span>
          </li>
        </ul>
      </div>

    </div>
  )
}
