import React from 'react'
import css from './style.module.scss'
import cn from 'classnames'
import { InputText, Button, Checkbox, Phone } from 'components/ui'
import { userFieldNames } from 'components/user/constants'

const AccountSettings = ({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  setFieldValue,
  onClickChangePassword,
}) => {
  return (
    <div className='accForm form-layout'>
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className='formField'>
          <div className={css.password}>
            <InputText
              disabled
              placeholder="Пароль"
              value={'abcdefgh'}
              type="password"
              name="password-entry"
              autoComplete="off"
              onChange={() => {}}
            />
          </div>
          <button
            className={cn(css.changePass, 'a')}
            onClick={onClickChangePassword}
          >
            Сменить пароль
          </button>
        </div>

        <div className='formField'>
          <InputText
            placeholder="E-mail"
            name={userFieldNames.EMAIL}
            value={values[userFieldNames.EMAIL]}
            onChange={handleChange}
            error={touched[userFieldNames.EMAIL] && errors[userFieldNames.EMAIL]}
          />
        </div>

        <div className='formField'>
          <Phone
            placeholder="Номер телефона"
            name={userFieldNames.PHONE}
            value={values[userFieldNames.PHONE]}
            setFieldValue={setFieldValue}
            country={'ru'}
            disabled
          />
        </div>

        <div className={cn('formField', css.subscribe)}>
          <div>
            <Checkbox
              id="subscribe"
              name={userFieldNames.SUBSCRIBE}
              defaultChecked={values[userFieldNames.SUBSCRIBE]}
              onChange={handleChange}
              value={values[userFieldNames.SUBSCRIBE]}
              text="Хочу получать новости от shabbatshalom.ru"
            />
          </div>
        </div>
        <div className='formButton'>
          <Button blue text="Сохранить изменения" type="submit"/>
        </div>
      </form>
    </div>
  )
}

export default AccountSettings
