import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect, useDispatch } from 'react-redux'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { values as getValues } from 'ramda'

import { fetchCities } from 'store/modules/cities/actions'
import { openLogInPopup, openSmsConfirmPopup } from 'components/user/actions'
import { API_URL } from 'src/config'
import { transformDataForSelect } from 'utils'
import { userFieldNames as fieldNames } from 'components/user/constants'
import {
  checkServerValidationErrors,
  // preparePhoneNumber,
  prepareDateString
} from 'utils'
import UserSignUp from './UserSignUp'

function UserSignUpContainer({
  onClickLogIn,
  onSubmitSuccess,
  cities,
}) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCities())
  }, [])

  const filterInt = function(int) {
    if (/^[-+]?(\d+|Infinity)$/.test(int)) {
      return Number(int)
    } else {
      return NaN
    }
  }

  const isLeapYear = function(y) { return !((y % 4) || (!(y % 100) && y % 400)) }

  const isDateCorrect = function(date) {
    // date is not string
    if (typeof date !== 'string') return false

    // date not match pattern 'dd/dd/dddd'
    if(!date.match(/\d\d\/\d\d\/\d\d\d\d/)) return false
    
    const [dd, mm, yyyy] = date.split('/').map(n => filterInt(n.replaceAll('_', '')))

    // day, month or year is not a number
    if (dd === NaN || mm === NaN || yyyy === NaN) return false

    // month is lower than 1 or higher than 12 
    if (mm < 1 || mm > 12) return false
    
    // day is lower than 1 or higher than 31
    if (dd < 1 || dd > 31) return false

    // day is higer than 30 for apr, jun, sep, nov
    if ([4,6,9,11].indexOf(mm) > -1 && dd > 30) return false
    
    // day is higher than 28/29 for feb
    if (isLeapYear(yyyy) && mm === 2 && dd > 29) return false
    if (!isLeapYear(yyyy) && mm === 2 && dd > 28) return false

    // year is lower than 1890 or higher than current year
    if (yyyy < 1890 || yyyy > (new Date()).getFullYear()) return false

    return true
  }

  const formik = useFormik({
    initialValues: {
      [fieldNames.LAST_NAME]: '',
      [fieldNames.FIRST_NAME]: '',
      [fieldNames.PATRONYMIC]: '',
      [fieldNames.GENDER]: '',
      [fieldNames.BIRTH_DAY]: '',
      [fieldNames.PHONE]: '',
      [fieldNames.EMAIL]: '',
      [fieldNames.AGREE]: false,
      // [fieldNames.SUBSCRIBE]: true,
      [fieldNames.ADDRESS]: '',
      [fieldNames.CITY]: null,
    },

    validationSchema: Yup.object().shape({
      [fieldNames.LAST_NAME]: Yup.string().required('Обязательное поле'),
      [fieldNames.FIRST_NAME]: Yup.string().required('Обязательное поле'),
      [fieldNames.PATRONYMIC]: Yup.string(),
      [fieldNames.PHONE]: Yup.string().required('Обязательное поле'),
      [fieldNames.GENDER]: Yup.string().oneOf(['1', '2']).required('Обязательное поле'),
      [fieldNames.BIRTH_DAY]:
        Yup
        .string()
        // .matches(/\d\d\/\d\d\/\d\d\d\d/, 'Введите правильный формат даты')
        .required('Обязательное поле')
        .test({
          name: 'is-data-correct',
          exclusive: true,
          params: {},
          message: 'Некорректное значение даты',
          test: isDateCorrect
        }),
      [fieldNames.AGREE]: Yup.boolean().oneOf([true], 'Обязательное поле'),
      [fieldNames.EMAIL]: Yup.string().email('Некорректный email').required('Обязательное поле'),
    }),

    onSubmit: (values, { setFieldError }) => {
      const preparedValues = {
        ...values,
        // [fieldNames.PHONE]: preparePhoneNumber(values[fieldNames.PHONE]),
        [fieldNames.BIRTH_DAY]: prepareDateString(values[fieldNames.BIRTH_DAY])
      }

      const submit = async () => {
        try {
          await axios({
            method: 'post',
            url: `${API_URL}/user/`,
            data: {...preparedValues},
            headers: {
              'Accept': 'application/json'
            }
          })

          onSubmitSuccess(preparedValues[fieldNames.PHONE])
        } catch(err) {
          checkServerValidationErrors({
            error: err,
            fieldNames: getValues(fieldNames),
            onMatch: (name, validationError) => {
              setFieldError(name, validationError)
            }
          })
        }
      }
      submit()
    }
  })

  return (
    <UserSignUp
      values={formik.values}
      errors={formik.errors}
      touched={formik.touched}
      handleSubmit={formik.handleSubmit}
      handleChange={formik.handleChange}
      setFieldValue={formik.setFieldValue}
      onClickLogIn={onClickLogIn}
      cities={transformDataForSelect(cities.data)}
    />
  )
}

export default connect(
  state => ({
    cities: state.cities
  }),
  dispatch => ({
    onClickLogIn: bindActionCreators(openLogInPopup, dispatch),
    onSubmitSuccess: bindActionCreators(openSmsConfirmPopup, dispatch),
  })
)(UserSignUpContainer)
