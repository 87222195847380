import React from 'react'
import PropTypes from 'prop-types'
import css from './style.module.scss'
import CheckboxMarkSvgSrc from './img/checkbox-mark.inline.svg'
import cn from 'classnames'

export default Checkbox

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string
}

function Checkbox({
  id,
  name,
  text,
  value,
  defaultChecked = false,
  onChange,
  error,
  smallText,
  tabIndex = undefined,
}) {
  return (
    <div className={css.checkboxWrap}>
      <input
        type="checkbox"
        id={id}
        name={name}
        defaultChecked={defaultChecked}
        onChange={onChange}
        tabIndex={tabIndex}
      />
      <label htmlFor={id} className={cn(css.label, error && css.__error)}>
        <div className={css.field}><CheckboxMarkSvgSrc /></div>
        <p
          className={cn(css.text, smallText && css.small, 'label ')}
          dangerouslySetInnerHTML={{__html: text}}
        />
      </label>

      {error &&
        <p className='validation__error caption red'>{error}</p>
      }
    </div>
  )
}
