import { pipe, values, map } from 'ramda'

export default function (data, options = {
  valueKey: 'id',
  labelKey: 'name',
  hasDeliveryKey: 'has_delivery',
}) {
  return pipe(
    values,
    map(el => ({
      value: el[options.valueKey].toString(),
      label: el[options.labelKey].toString(),
      has_delivery: el[options.hasDeliveryKey],
    }))
  )(data)
}
