import React from 'react'
import White from './img/link-away-white.inline.svg'
import Blue from './img/link-away-blue.inline.svg'

export default function ({
  blue = false,
  ...rest
}) {
  if (blue) {
    return <Blue { ...rest }/>
  } else {
    return <White { ...rest }/>
  }
}
