import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { triggerDOMElementOnInput } from 'utils'
import { v1 as uuid } from 'uuid'
import css from './style.module.scss'

const VALUE_PROP_TYPE = PropTypes.shape({
  value: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired
})

LeftRightSwitcher.propTypes = {
  defaultSelected: PropTypes.any,

  values: PropTypes.shape({
    left: VALUE_PROP_TYPE,
    right: VALUE_PROP_TYPE
  }).isRequired,

  onChange: PropTypes.func.isRequired,

  name: PropTypes.string.isRequired
}

export default function LeftRightSwitcher({
  values,
  onChange,
  name,
  defaultSelected
}) {
  const [isReady, setIsReady] = useState(false)

  const [switcherId, setSwitcherId] = useState('')

  const valueInputRef = useRef(null)

  const [
    selectedValue,
    setSelectedValue
  ] = useState(values.left.value)

  const toggleSwitchHandler = event => {
    if (event.target.checked) {
      setSelectedValue(values.right.value)
    } else {
      setSelectedValue(values.left.value)
    }
  }

  useEffect(() => {
    if (!isReady) {
      return
    }

    triggerDOMElementOnInput(valueInputRef.current)
  }, [selectedValue])

  useEffect(() => {
    setSwitcherId(uuid())
    
    if (defaultSelected) {
      setSelectedValue(defaultSelected)
    }

    setIsReady(true)
  }, [])

  if (!isReady) {
    return null
  }

  return (
    <div className={css.toggle}>
      <input
        type="text"
        value={selectedValue}
        name={name}
        ref={valueInputRef}
        onInput={e => onChange(e)}
        readOnly
        hidden
      />

      <label htmlFor={switcherId} className={css.switcher}>
        <input
          className={css.switcherCheckbox}
          type="checkbox"
          defaultChecked={defaultSelected === values.right.value}
          value={''}
          onChange={toggleSwitchHandler}
          id={switcherId}
        />
        <div
          className={css.switcherSpan}
          data-text-left={values.left.label}
          data-text-right={values.right.label}
        >
        </div>
      </label>
    </div>
  )
}