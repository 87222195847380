import React from 'react'
import css from './style.module.scss'
import { InputText, Button } from 'components/ui'
import cn from 'classnames'
import { userFieldNames as fieldNames } from 'components/user/constants'

export default function ({
  handleChange,
  handleSubmit,
  values,
  errors,
  touched
}) {
  return (
    <div className={cn(css.passChange, 'form-layout')}>
      <form onSubmit={handleSubmit}>
        <div className='form-field'>
          <InputText
            placeholder="Старый пароль"
            type="password"
            onChange={handleChange}
            name={fieldNames.OLD_PASSWORD}
            value={values[fieldNames.OLD_PASSWORD]}
            error={touched[fieldNames.OLD_PASSWORD] &&
              errors[fieldNames.OLD_PASSWORD]
            }
          />
        </div>

        <div className='form-field'>
          <InputText
            placeholder="Новый пароль"
            type="password"
            onChange={handleChange}
            name={fieldNames.NEW_PASSWORD}
            value={values[fieldNames.NEW_PASSWORD]}
            error={touched[fieldNames.NEW_PASSWORD] &&
              errors[fieldNames.NEW_PASSWORD]
            }
          />
        </div>

        <div className='form-field'>
          <InputText
            placeholder="Подтвердите новый пароль"
            type="password"
            onChange={handleChange}
            name={fieldNames.CONFIRM_PASSWORD}
            value={values[fieldNames.CONFIRM_PASSWORD]}
            error={touched[fieldNames.CONFIRM_PASSWORD] &&
              errors[fieldNames.CONFIRM_PASSWORD]
            }
          />
        </div>

        <div className="form-button">
          <Button blue text="Сохранить" type="submit" />
        </div>
      </form>

    </div>
  )
}
