import axios from 'axios'
import { API_URL } from 'src/config'

export const actionTypes = {
  ORDER_IS_AVAILABLE: 'ORDER_IS_AVAILABLE',
}

export function ordersIsAvailable() {
  return async dispatch => {
    try {
      const response = await axios({
        method: 'get',
        url: `${API_URL}/project/`,
      })

      dispatch({
        type: actionTypes.ORDER_IS_AVAILABLE,
        payload: {
          ...response.data
        }
      })
    } catch (e) {
      console.error(e)
    }
  }
}