import { createStore, applyMiddleware } from 'redux'
import rootReducer from './root-reducer'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import reduxCookiesMiddleware, { getStateFromCookies } from 'redux-cookies-middleware'
import { initialState as userInitialState } from 'components/user/reducer'

const logger = createLogger({})

// state to persist in cookies
const persistReduxPaths = {
  'user.authToken': {
    name: 'persist_user_auth_token'
  }
}

let initialState = {
  user: userInitialState
}

initialState = getStateFromCookies(initialState, persistReduxPaths)

export const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(
    thunk,
    logger,
    reduxCookiesMiddleware(persistReduxPaths)
  )
)
