import React from 'react'
import css from './style.module.scss'
import { Link } from "gatsby"

export default function () {
  return (
    <div className={css.sidebar}>
      <ul>
        <li>
          <Link to="/account/orders/"
                activeClassName={css.active}>
            Мои заказы
          </Link>
        </li>
        <li>
          <Link to="/account/"
                activeClassName={css.active}>
            Личные данные
          </Link>
        </li>
        <li>
          <Link to="/account/settings/"
                activeClassName={css.active}>
            Настройки аккаунта
          </Link>
        </li>
      </ul>
    </div>
  )
}
