import React from 'react'
import css from './style.module.scss'
import { InputText, Button, Phone } from 'components/ui'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { userFieldNames as fieldNames } from 'components/user/constants'
import { Link } from 'gatsby'

UserLogIn.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired
}

export default function UserLogIn({
  values,
  handleChange,
  handleSubmit,
  setFieldValue,
  onClickForgotPassword,
  onClickMakeOrder,
  errors,
  touched,
  serverError
}) {
  return (
    <div className={cn(css.logIn, 'form-layout')}>
      <div className={css.important}>
        <p className={css.importantText}>
          Если вы уже зарегистрированы на <a href='https://shanatova.ru/' target='_blank' rel='noopener noreferrer'>shanatova.ru</a>, то регистрация не требуется. Войдите в свою учетную запись по зарегистрированному ранее номеру телефона и паролю.
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='form-field'>
          <Phone
            placeholder="Номер телефона"
            name={fieldNames.PHONE}
            setFieldValue={setFieldValue}
            error={touched[fieldNames.PHONE] && errors[fieldNames.PHONE]}
            country={'ru'}
          />
        </div>
        <div className={cn('form-field', css.password)}>
          <InputText
            placeholder="Пароль"
            name={fieldNames.PASSWORD}
            value={values[fieldNames.PASSWORD]}
            onChange={handleChange}
            type="password"
            error={touched[fieldNames.PASSWORD] && errors[fieldNames.PASSWORD]}
          />
          <span
            onClick={onClickForgotPassword}
            className={cn(css.passwordForgot, "label-2 primary-color a")}
            role='button'
            tabIndex='0'
            onKeyDown={() => {}}
          >
            Забыли пароль?
          </span>
        </div>

        <div className="form-button">
          {
            serverError && 
              <React.Fragment>
                <p className="caption red">{serverError}</p>
                <p><br /></p>
              </React.Fragment>
          }
          <Button
            blue
            text="Войти"
          />
        </div>
      </form>

      <div className='text-center'>
        <p className='caption grey'>Ещё нет учетной записи?</p>
        <button 
          className={cn(css.registration, "label-2 primary-color pointer a")}
          onClick={onClickMakeOrder}
        >
          Оформить заявку и зарегистрироваться
        </button>
      </div>
    </div>
  )
}
