import React, { useState, useEffect } from 'react'
import UserSmsConfirmView from './View'
import { useFormik } from 'formik'
import { userFieldNames } from 'components/user/constants'
import { useDispatch } from 'react-redux'
import { validateSms } from 'components/user/actions'
import { checkServerValidationErrors, checkServerError } from 'utils'
import axios from 'axios'
import { API_URL } from 'src/config'
import * as Yup from 'yup'

export default function UserSmsConfirm({ phone, onSubmitSuccess }) {
  const dispatch = useDispatch()
  const [serverError, setServerError] = useState('')
  const [seconds, setSeconds] = useState(120);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setSeconds(seconds => seconds - 1);
    }, 1000);

    if (seconds < 0) {
      clearInterval(interval)
    }

    return () => clearInterval(interval);
  }, [seconds]);

  function getTimeLeft() {
    if (seconds < 0) {
      return null
    }
    const m = Math.floor(seconds / 60)
    const s = seconds % 60

    return `
      ${m}:${s < 10 ? `0${s}` : s}
    `
  }

  function onClickResendHandler() {
    axios.put(`${API_URL}/user/restore/`, {
      [userFieldNames.PHONE]: phone
    }).then(() => {
      setSeconds(120)
    })
  }

  const formik = useFormik({
    initialValues: {
      [userFieldNames.SMS_CODE]: ''
    },

    validationSchema: Yup.object().shape({
      [userFieldNames.SMS_CODE]: Yup.string().required('Обязательное поле')
    }),

    onSubmit: (values, { setFieldError }) => {
      setServerError('')
      
      dispatch(validateSms(
        phone,
        values[userFieldNames.SMS_CODE]
      ))
      .then(() => {
        if (onSubmitSuccess) {
          onSubmitSuccess(values)
        }
      })
      .catch(err => {
        checkServerValidationErrors({
          error: err,
          fieldNames: [userFieldNames.SMS_CODE],
          onMatch: (name, error) => {
            setFieldError(name, error)
          }
        })

        checkServerError({
          error: err,
          onMatch: (error) => {
            setServerError(error)
          }
        })
      })
    }
  }, [phone])

  return <UserSmsConfirmView
    values={formik.values}
    errors={formik.errors}
    handleChange={formik.handleChange}
    handleSubmit={formik.handleSubmit}
    serverError={serverError}
    timeLeft={getTimeLeft()}
    onClickResend={onClickResendHandler}
  />
}
