function makeTriggerDOMElementOnInput() {
  if (typeof window === 'undefined') {
    return false
  }

  const event = new window.Event('input', { bubbles: true })

  return DOMElement => {
    DOMElement.dispatchEvent(event)
  }
}

const triggerDOMElementOnInput = makeTriggerDOMElementOnInput()

export default triggerDOMElementOnInput
