import axios from 'axios'
import { API_URL } from 'src/config'

export const actionTypes = {
  CITIES_SET_DATA: 'CITIES_SET_DATA'
}

export function fetchCities() {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${API_URL}/city/`)
      dispatch({
        type: actionTypes.CITIES_SET_DATA,
        payload: {
          cities: [...res.data]
        }
      })
      return Promise.resolve()
    } catch(err) {
      return Promise.reject(err)
    }
  }
}
