import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { closePopup } from 'components/popups/actions'
import * as Yup from 'yup'
import { navigate } from 'gatsby'

import { preparePhoneNumber, checkServerError } from 'utils'
import UserLogIn from './UserLogIn'
import { login } from 'components/user/actions'
import { userFieldNames as fieldNames } from 'components/user/constants'

const mapDispatchToProps = dispatch => ({
  closePopup: bindActionCreators(closePopup, dispatch),
  login: bindActionCreators(login, dispatch)
})

export default connect(
  null,
  mapDispatchToProps
)(UserLogInContainer)

UserLogInContainer.propTypes = {
  login: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  onSuccessLogin: PropTypes.func
}

function UserLogInContainer({
  login,
  closePopup,
  onSuccessLogin,
  onClickForgotPassword
}) {
  const [serverError, setServerError] = useState('')

  const onClickMakeOrder = () => {
    navigate('/make-order/')
    closePopup()
  }

  const formik = useFormik({
    initialValues: {
      [fieldNames.PHONE]: '',
      [fieldNames.PASSWORD]: ''
    },

    validationSchema: Yup.object().shape({
      [fieldNames.PHONE]: Yup.string().required('Обязательное поле'),
      [fieldNames.PASSWORD]: Yup.string().required('Обязательное поле'),
    }),

    onSubmit: (values, setFieldError) => {
      setServerError('')
      
      login(
        preparePhoneNumber(values[fieldNames.PHONE]),
        values[fieldNames.PASSWORD]
      ).then(() => {
        if (onSuccessLogin) {
          onSuccessLogin()
        }
      }).catch(err => {
        checkServerError({
          error: err,
          onMatch: (errorMessage) => {
            setServerError(errorMessage)
          }
        })
      })
    }
  })

  return <UserLogIn
    values={formik.values}
    errors={formik.errors}
    touched={formik.touched}
    handleChange={formik.handleChange}
    handleSubmit={formik.handleSubmit}
    setFieldValue={formik.setFieldValue}
    onClickForgotPassword={onClickForgotPassword}
    onClickMakeOrder={onClickMakeOrder}
    serverError={serverError}
  />
}
