import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import css from './style.module.scss'
import { IconCross } from 'components/icons'

const ERROR = {
  NOT_SUPPORTED_EXTENSION: 'NOT_SUPPORTED_EXTENSION',
  FILESIZE_TOO_LARGE: 'FILESIZE_TOO_LARGE'
}

class File extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pictures: [...props.defaultImages],
      files: [],
      fileErrors: []
    }
    this.inputElement = ''
    this.onDropFile = this.onDropFile.bind(this)
    this.onUploadClick = this.onUploadClick.bind(this)
    this.triggerFileUpload = this.triggerFileUpload.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevState.files !== this.state.files){
      this.props.onChange(this.state.files, this.state.pictures)
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.defaultImages !== this.props.defaultImages){
      this.setState({pictures: nextProps.defaultImages})
    }
  }

  hasExtension(fileName) {
    const pattern = '(' + this.props.imgExtension.join('|').replace(/\./g, '\\.') + ')$'
    return new RegExp(pattern, 'i').test(fileName)
  }

  onDropFile(e) {
    const files = e.target.files
    const allFilePromises = []
    const fileErrors = []

    // Iterate over all uploaded files
    for (let i = 0; i < files.length; i++) {
      let file = files[i]
      let fileError = {
        name: file.name,
      }
      // Check for file extension
      if (!this.hasExtension(file.name)) {
        fileError = Object.assign(fileError, {
          type: ERROR.NOT_SUPPORTED_EXTENSION
        })
        fileErrors.push(fileError)
        continue
      }
      // Check for file size
      if(file.size > this.props.maxFileSize) {
        fileError = Object.assign(fileError, {
          type: ERROR.FILESIZE_TOO_LARGE
        })
        fileErrors.push(fileError)
        continue
      }

      allFilePromises.push(this.readFile(file))
    }

    this.setState({
      fileErrors
    })

    const {singleImage} = this.props;

    Promise.all(allFilePromises).then(newFilesData => {
      const dataURLs = singleImage?[]:this.state.pictures.slice()
      const files = singleImage?[]:this.state.files.slice()

      newFilesData.forEach(newFileData => {
        dataURLs.push(newFileData.dataURL)
        files.push(newFileData.file)
      });

      this.setState({pictures: dataURLs, files: files})
    });
  }

  onUploadClick(e) {
    // Fixes https://github.com/JakeHartnell/react-images-upload/issues/55
    e.target.value = null
  }

  readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = function (e) {
        let dataURL = e.target.result
        dataURL = dataURL.replace(";base64", `;name=${file.name};base64`)
        resolve({file, dataURL})
      }

      reader.readAsDataURL(file)
    })
  }

  removeImage(picture) {
    const removeIndex = this.state.pictures.findIndex(e => e === picture)
    const filteredPictures = this.state.pictures.filter((e, index) => index !== removeIndex)
    const filteredFiles = this.state.files.filter((e, index) => index !== removeIndex)

    this.setState({pictures: filteredPictures, files: filteredFiles}, () => {
      this.props.onChange(this.state.files, this.state.pictures)
    })
  }

  renderErrors() {
    const { fileErrors } = this.state
    return fileErrors.map((fileError, index) => {
      return (
        <div className={cn(css.errorMessage, 'caption red')} key={index}>
          * {fileError.name} {fileError.type === ERROR.FILESIZE_TOO_LARGE ? this.props.fileSizeError: this.props.fileTypeError}
        </div>
      )
    })
  }

  renderPreview() {
    return this.state.pictures.map((picture, index) => {
      return (
        <div className={css.item} key={index}>
          <div className={css.itemImage}>
            <img src={picture} alt={'alt'} />
          </div>
          <div className={css.itemTitle}>
            <p>{this.state.files[index].name}</p>
          </div>
          <div
            className={css.itemRemove}
            onClick={() => this.removeImage(picture)}
          >
            <IconCross />
          </div>
        </div>
      )
    })
  }

  triggerFileUpload() {
    this.inputElement.click()
  }

  clearPictures() {
    this.setState({pictures: []})
  }

  render() {
    return (
      <div className={css.inputFile}>
        <div className={css.files}>
          {this.renderPreview()}
        </div>
        <div className={css.errors}>
          {this.renderErrors()}
        </div>
        <div className={css.uploadArea}>
          <button
            type='button'
            className={css.uploadButton}
            onClick={this.triggerFileUpload}
          >
            {this.props.buttonText}
          </button>
          <input
            type="file"
            ref={input => this.inputElement = input}
            name={this.props.name}
            multiple={!this.props.singleImage}
            onChange={this.onDropFile}
            onClick={this.onUploadClick}
            accept={this.props.accept}
          />
        </div>
      </div>
    )
  }
}

File.defaultProps = {
  // className: '',
  // fileContainerStyle: {},
  // buttonClassName: "",
  // buttonStyles: {},
  withPreview: false,
  accept: "image/*",
  name: "",
  withIcon: true,
  buttonText: "Прикрепить фото",
  // buttonType: "button",
  withLabel: true,
  label: "Max file size: 5mb, accepted: jpg|gif|png",
  labelStyles: {},
  labelClass: "",
  imgExtension: ['.jpg', '.jpeg', '.gif', '.png'],
  maxFileSize: 5242880,
  fileSizeError: " размер файла слишком большой",
  fileTypeError: " формат файла не поддерживается",
  // errorClass: "",
  // style: {},
  // errorStyle: {},
  singleImage: false,
  onChange: () => {},
  defaultImages: []
}

File.propTypes = {
  // style: PropTypes.object,
  // fileContainerStyle: PropTypes.object,
  // className: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  // buttonClassName: PropTypes.string,
  // buttonStyles: PropTypes.object,
  // buttonType: PropTypes.string,
  withPreview: PropTypes.bool,
  accept: PropTypes.string,
  name: PropTypes.string,
  withIcon: PropTypes.bool,
  buttonText: PropTypes.string,
  withLabel: PropTypes.bool,
  label: PropTypes.string,
  labelStyles: PropTypes.object,
  labelClass: PropTypes.string,
  imgExtension: PropTypes.array,
  maxFileSize: PropTypes.number,
  fileSizeError: PropTypes.string,
  fileTypeError: PropTypes.string,
  // errorClass: PropTypes.string,
  // errorStyle: PropTypes.object,
  singleImage: PropTypes.bool,
  defaultImages: PropTypes.array
}

export default File