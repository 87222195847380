import { actionTypes } from './actions'

const initialState = {
  popupType: null,
  popupProps: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_POPUP:
      return {
        popupType: action.popupType,
        popupProps: action.popupProps
      }
    case actionTypes.CLOSE_POPUP:
      return initialState
    default:
      return state
  }
}
